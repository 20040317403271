import { RouteRecordRaw } from "vue-router";

import { PermissionController } from "../api/models/user";

const routes: RouteRecordRaw[] = [
  { path: "/", redirect: "/login" },
  {
    path: "/",
    component: () => import("../components/LayoutNavbarVertical.vue"),
    children: [
      {
        path: "blog-categoria/:id?",
        name: "Blog categoria",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/admin/blog/category/BlogPostCategoryForm.vue"),
          table: () => import("../views/admin/blog/category/BlogPostCategoryTable.vue"),
        },
        meta: {
          requiresAuth: true,
          permissionController: PermissionController.BLOG_POST_CATEGORIES,
          priority: 1,
        },
      },
      {
        path: "blog-post/:id?",
        name: "Blog post",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/admin/blog/post/BlogPostForm.vue"),
          table: () => import("../views/admin/blog/post/BlogPostTable.vue"),
        },
        meta: {
          requiresAuth: true,
          permissionController: PermissionController.BLOG_POSTS,
          priority: 2,
        },
      },
      {
        path: "clientes/:id?",
        name: "Clientes",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/admin/customer/CustomerForm.vue"),
          table: () => import("../views/admin/customer/CustomerTable.vue"),
        },
        meta: { requiresAuth: true, permissionController: PermissionController.USERS, priority: 3 },
      },
    ],
  },
  {
    path: "/",
    component: () => import("../components/LayoutNavbarVertical.vue"),
    props: {
      tabItems: [
        { name: "Dados pessoais", path: "/minha-conta" },
        { name: "Usuários do painel", path: "/usuarios" },
      ],
    },
    children: [
      {
        path: "minha-conta",
        name: "Minha conta",
        component: () => import("../views/admin/settings/MyAccount.vue"),
        meta: { requiresAuth: true, priority: 4 },
      },
      {
        path: "usuarios/:id?",
        name: "Usuários",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/admin/settings/users/UserForm.vue"),
          table: () => import("../views/admin/settings/users/UserTable.vue"),
        },
        meta: { requiresAuth: true, permissionController: PermissionController.USERS, priority: 4 },
      },
    ],
  },
];

export default routes;
